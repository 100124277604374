import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/Banner";
import Counter from "../components/Counter";
import Paragraph from "../utils/Paragraph";
export default function LastMile() {
  return (
    <Layout>
      <Seo
        title="Last Mile Access"
        url="https://migrantresilience.org/last-mile-access"
      />
      <Banner
        src="/images/Banner Last Mile Access page.jpg"
        position="object-center"
      />
      <div className="w-full flex flex-col justify-center items-start relative z-0 lg:px-28 md:px-12 px-4 overflow-hidden pb-20 pt-6 lg:pt-0">
        <h1 className="text-left lg:mt-4 mb-4 text-darkblue md:text-4xl text-2xl font-bold">
          Last Mile Access
        </h1>
        <p className="text-body md:text-base text-sm">
          MRC directly facilitates government social protection — both social
          security benefits and worker protections — for the most vulnerable
          migrants living in the most isolated circumstances. Our approach is
          anchored on community-rootedness together with rigorous use of
          technology and data analytics to drive impact.
        </p>
        <img
          src="/images/dot-pattern.png"
          className="absolute top-0 left-20 w-80 object-contain opacity-80 -z-1"
        ></img>
        <div className="grid lg:grid-cols-2 lg:grid-rows-1 grid-cols-1 auto-rows-auto gap-12 w-full lg:my-8 my-12">
          <IconBox
            icon={"/images/social-security.svg"}
            title="Social Security Benefits: Facilitating direct access to government benefits and building the capacity of communities to deepen their access to benefits even without MRC support."
          >
            <ul className="pl-4 list-disc leading-tight gap-4 flex flex-col md:text-base text-sm">
              <li>
                <strong>
                  Building awareness of social security benefits and simplifying
                  eligibility checks by registering households on our Jan Saathi
                  mobile app
                </strong>
              </li>
              <li>
                <strong>
                  Accompanying migrants through the process of applying for
                  benefits,
                </strong>{" "}
                from helping secure documents to prove eligibility and filling
                out application forms to tracking delivery and supporting them
                in escalating complaints if they are denied benefits
              </li>
              <li>
                <strong>
                  Identifying and building the capacity of at least one
                  volunteer in each village
                </strong>{" "}
                to support the community in accessing benefits
              </li>
            </ul>
          </IconBox>
          <IconBox
            icon={"/images/worker.svg"}
            title="Worker Protection: Building awareness to prevent exploitation and providing redressal support in cases of exploitation."
          >
            <ul className="pl-4 list-disc leading-tight gap-4 flex flex-col md:text-base text-sm">
              <li>
                <strong>
                  Community outreach including pre-departure and post-arrival
                  trainings to raise awareness
                </strong>{" "}
                on how to avoid forced labour and seek out support when faced
                with exploitation
              </li>
              <li>
                <strong>
                  Operating a multi-lingual, national toll-free helpline
                </strong>{" "}
                where migrant workers can call for free assistance with
                disputes, emergencies, or seek information on social security
                benefits. Our team records potential cases of exploitation in a
                case management system and alerts proximate MRC field teams for
                verification
              </li>
              <li>
                <strong>
                  Providing redressal support by working with the district
                  police and government officials,
                </strong>{" "}
                providing legal aid through a team of dedicated lawyers and
                facilitating access to government-mandated rehabilitation
              </li>
            </ul>
          </IconBox>
        </div>
        <p className="text-body md:text-base text-sm">
          Crucial to our implementation efforts is building the capacity of
          others to sustainably facilitate social protections for migrants. We
          partner with Community-based Organisations (CBOs) already working in
          migrant communities through relevant programs, such as health and
          education. These CBOs replicate and execute MRC’s model and have full
          access to MRC’s technology, knowledge and infrastructure.
        </p>
        <p className="text-darkblue font-bold md:text-base text-sm leading-none lg:max-w-[95%] mt-8 mb-4">
          Till date MRC has:
        </p>
        <ul className="pl-4 !list-disc leading-tight lg:gap-3 gap-2 flex flex-col md:text-base text-sm w-full overflow-hidden">
          <li>
            <div className="flex gap-1 relative shrink-0">
              <span className="shrink-0">Enabled delivery of </span>
              <Counter
                end={7.5}
                decimals={1}
                className="md:text-base text-sm text-[#19bbf9] font-bold"
                suffix={"&nbsp;million+"}
                // prefix={}
              />{" "}
              <span className="shrink-0 flex-wrap">
                social security benefits
              </span>
            </div>
          </li>
          <li>
            <div className="flex gap-1 relative shrink-0">
              <Counter
                end={254}
                decimals={0}
                className="md:text-base text-sm text-[#19bbf9] font-bold"
                suffix={"k+"}
                // prefix={}
              />{" "}
              <span className="shrink-0 flex-wrap">
                calls received on the helpline
              </span>
            </div>
          </li>
          <li>
            <div className="flex gap-1 relative shrink-0">
              USD
              <Counter
                end={2.8}
                decimals={1}
                className="md:text-base text-sm text-[#19bbf9] font-bold"
                suffix={"&nbsp;million+"}
                // prefix={}
              />{" "}
              <span className="shrink-0 flex-wrap">
                compensation recovered for workers
              </span>
            </div>
          </li>

          <li>
            <div className="flex gap-1 relative shrink-0">
              <span className="shrink-0">Partnered with</span>
              <Counter
                end={24}
                decimals={0}
                className="md:text-base text-sm text-[#19bbf9] font-bold"
                // suffix={"k+"}
                // prefix={}
              />{" "}
              <span className="shrink-0 flex-wrap">
                grassroots organizations
              </span>
            </div>
          </li>
        </ul>
      </div>
    </Layout>
  );
}
export const IconBox = ({ icon, title, children }) => {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-4 p-4 rounded-2xl group hover:bg-white bg-[#F1F7FF] shadow-xl border-1 border-transparent hover:border-darkblue transition-all duration-300 ease">
      <div className="lg:h-24 lg:w-24 w-20 h-20 rounded-2xl lg:p-4 p-3 flex flex-col shrink-0 justify-center items-center group-hover:bg-[#F1F7FF] bg-white text-4xl text-darkblue transition-all duration-300 ease">
        <img src={icon} className="w-full object-contain" />
      </div>
      <p className="text-darkblue font-bold md:text-base text-base leading-tight lg:max-w-[95%] mb-0">
        {title}
      </p>
      <p className="text-darkblue font-semibold md:text-base text-base leading-tight lg:max-w-[95%] mb-0 underline">
        Key activities include:
      </p>
      <div className="flex flex-col w-full">{children}</div>
    </div>
  );
};
